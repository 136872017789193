<template>
  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Master Batch Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Name:</label>
          <div class="col-lg-10">
            <input type="text" class="form-control" placeholder="Enter Item Name here..." maxlength="100" v-if="item" v-model="item.name" autofocus required>
          </div>
        </div>

        <!--<div class="form-group row">-->
          <!--<label class="col-lg-2 col-form-label">Print Name:</label>-->
          <!--<div class="col-lg-10">-->
            <!--<input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100" v-if="item" v-model="item.p_name">-->
          <!--</div>-->
        <!--</div>-->

        <div class="form-group row">

          <label class="col-lg-2 col-form-label">Brand:</label>
          <div class="col-lg-10">
            <select class="form-control" v-if="item" v-model="item.brand.id">
              <option v-for="brand in brands" v-bind:value="brand.id">
                {{ brand.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-2 col-form-label">Unit of measure:</label>
          <div class="col-lg-10">
            <select class="form-control" v-if="item" v-model="item.unit.id" disabled>
              <option value="1">Nos</option>
              <option value="2" selected>Kgs</option>
              <option value="3">Mts</option>
            </select>
          </div>

        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  import moment from 'moment-timezone'
  export default {
    name: 'MasterBatchForm',
    data () {
      return {
        item: {},
        groups: [],
        categories: [],
        brands:[]
      }
    },
    props: {
      myitem: {
        type: Object,
        required: false,
        default: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
      }
    },
    beforeMount () {
      this.item = this.myitem; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      this.$data.item = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
    },
    mounted () {
      let self = this;

      self.loadBrands();
      // self.loadCategories();
    },
    methods: {
      closeWindow(){
        this.$emit('masterbatch_window_closed');
      },
      clear(){
        this.$data.item = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","group_id":1,"unit_id":1,"brand_id":1,"quality_id":1,"color_id":1,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}},"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"quality":{"id":1,"type":0,"name":"","status":"Active","weight":0,"colorcharts":[]},"color":{"id":1,"type":0,"name":"","status":"Active","weight":0,"chart":[]},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}}')
      },
      loadAll(){
        loadBrands();
        // loadCategories();
      },
      loadBrands(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/brands`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(resp.data != null){
              self.$data.brands = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      loadCategories(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/category/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(resp.data != null){
              self.$data.categories = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

      },
      saveDocument(){
        const self = this;

        self.$data.item.type = 'MasterBatch';
        self.$data.item.unit_id = 2;
        self.$data.item.brand_id = parseInt(self.$data.item.brand_id);
        self.$data.item.group_id = 1;
        self.$data.item.quality_id = 1;
        self.$data.item.color_id = 1;
        self.$data.item.min_qty = parseFloat(self.$data.item.min_qty);
        self.$data.item.max_qty = parseFloat(self.$data.item.max_qty);


        if(self.$data.item.name.trim().length < 1){
          alert("Invalid Name");
          return
        }

        const requestOptions = {
          method:  (self.$data.item.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.item)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}api/item`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.ok) {
            swal ( { title: "Success!" ,  text: data.msg, type:  "success"} );
            self.$emit('masterbatch_saved', resp.data);

          } else {
            swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
